<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="section-title margin-deliver">
                            {{$t(`Manage Users`)}}
                            <v-popover offset="10" :disabled="false"  style="display:initial;">
                                <button class="ej__tooltip__button ml-1"> <i class="eicon e-question"></i> </button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover href="https://easy.jobs/docs/add-team-member" target="_blank">
                                            {{ $t(`How to add team member?`) }}
                                        </a> <br/>
                                        <a href="https://easy.jobs/docs/manage-team-visibility" target="_blank">
                                            {{ $t(`How to manage team visibility?`) }}
                                        </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>

                        <button class="button primary-button text-capitalize" @click="goUserPermissionPage()">
                            <span class="icon"><i class="eicon e-plus"></i></span>
                            <span class="text-capitalize">{{$t(`Add New User`)}}</span>
                        </button>
                    </div>
                    <!-- data table -->
                    <div class="data-table user-table" v-if="isContentLoading">
                        <TableLoader></TableLoader>
                    </div>
                    <div class="data-table user-table" v-else>
                        <h4 class="empty-message" v-show="isEmpty">{{$t(`No User Found`)}}</h4>
                        <div class="table-wrap" v-show="!isEmpty">
                            <div class="table">
                                <div class="table__row table__head">
                                    <div class="table-cell">{{$t(`User Name`)}}</div>
                                    <div class="table-cell">{{$t(`Email Address`)}}</div>
                                    <div class="table-cell">{{$t(`Role`)}}</div>
                                    <div class="table-cell text-center">{{$t(`Actions`)}}</div>
                                </div>
                                <div class="table__row" v-for="(user, index) in users">
                                    <div class="table-cell user__info">
                                        <div class="d-flex align-items-center">
                                            <div class="user__image">
                                                <img :src="user.profile_image" :alt="user.name" class="w-100 img-fluid"/>
                                            </div>
                                            <h4 class="user__name" :title="user.name">{{ limitManagerName(user.name, 25) }}</h4>
                                        </div>
                                    </div>
                                    <div class="table-cell user__email" v-text="user.email"></div>
                                    <div class="table-cell user__role">
                                        <div class="d-flex">
                                            <span v-text="user.role"></span>
                                            <div></div>
                                            <span class="semi-role success-label owner__badge" v-if="user.isOwner">{{ $t(`Owner`) }}</span>
                                          <template  v-if="user.invited">
                                              <a href="javasript:void(0);" @click.prevent="resendInvite(user.id)"><span class="semi-role warning-label" >{{$t(`Re-Invite`)}}</span></a>
                                          </template>

                                        </div>
                                    </div>
                                    <div class="table-cell user-action">
                                        <div class="d-flex align-items center" v-if="!user.isOwner">
                                            <a href="javascript:void(0)"  @click.prevent="goUserPermissionUpdatePage(user)" class="control-button control-button--primary has_bg_color">
                                                <div class="control-button__icon">
                                                    <i class="eicon e-pencil"></i>
                                                </div>
                                                <span>{{$t(`Update`)}}</span>
                                            </a>
                                            <a   href="javascript:void(0)" @click.prevent="deleteUser(user.id, index)"
                                                class="control-button control-button--danger has_bg_color">
                                                <div class="control-button__icon">
                                                    <i class="eicon e-delete"></i>
                                                </div>
                                                <span>{{$t(`Delete`)}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    const DashboardLayout = ()=> import('../../../layouts/DashboardLayout');
    const SettingMenu = ()=> import('../../../components/company/SettingSidebar');
    const TableLoader = ()=> import('../../../components/_loaders/_TableLoader');
    import client from "../../../app/api/Client";
    import {mapState, mapActions} from 'vuex';
    import {SWITCH_TO_ACCOUNT} from "../../../constants/action-type";
    import {EventBus} from "../../../extra/event-bus";
    import {SWITCHED_TO_ANOTHER_ACCOUNT, SHOW_NOTIFICATION_SETTINGS} from "../../../constants/events";
    export default {
        components: {
            DashboardLayout,
            SettingMenu,
            TableLoader
        },

        data() {
            return {
                isContentLoading: true,
                users : [],
                updateData : null,
                addUser : false,
                updateUser : false,
                sendingEmail: false
            }
        },

        computed: {
            ...mapState(['user', 'company']),
            isEmpty() {
                return this.users.length === 0;
            },
            isProcessing(){
              return this.sendingEmail;
            }
        },
        methods: {
            ...mapActions([SWITCH_TO_ACCOUNT]),
            hideAddUserModal(){
                this.addUser = false;
                const el = document.body;
                el.classList.remove('modal-open');

            },
            hideUpdateUserModal() {
                this.updateUser = false;
                const el = document.body;
                el.classList.remove('modal-open');
            },
            async getUsers(){
                this.isContentLoading = true;
                try{
                    let {data: {data}} = await client().get('/company/setting/user');
                    this.users = data;

                }catch (e) {
                }
                this.isContentLoading =false;
            },

            goUserPermissionPage() {
                this.$router.push({name: 'company.setting.user.add'});
            },

            goUserPermissionUpdatePage(user) {
                this.$router.push({name: 'company.setting.user.update', params: { id: user.id }});
            },
            deleteUser(userId, index) {
                let message ={
                    title : this.$t(`Confirmation`),
                    body : this.$t(`user-delete-confirm-text`)
                };
                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };
                this.$dialog.confirm(message, dialogConfig).then(() => {
                    client().delete(`company/setting/user/${userId}/delete`)
                            .then(({data: {data, message}}) => {
                                    let email = this.users[index].email;
                                    this.users.splice(index, 1);
                                    this.$toast.success(this.$t(message));
                                    if(email == this.user.email) {
                                       this.changeAccount();
                                    }
                             }).catch(({response : { data }}) => {
                                 this.$toast.error(data.message);
                             });
                });
            },
           resendInvite(userId) {
              this.sendingEmail = true;
              client().get(`company/setting/user/${userId}/send-invitation`)
                 .then(({data: {data, message}}) => {
                    this.$toast.success(this.$t(message));
                    this.sendingEmail = false;
                 })
                  .catch(({response : { data }}) => {
                      this.$toast.error(data.message);
                      this.sendingEmail = false;
                 });
           },
            changeAccount() {
                this.user.companies = this.user.companies.filter((company) => company.id !== this.company.id);
                let accountType = "user";
                this[SWITCH_TO_ACCOUNT](null);
                EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, accountType);
                this.$router.push({
                    name: 'dashboard'
                })
            },
            userAdded(value) {
                if (value) {
                    this.users.push(value.data);
                }
                this.hideAddUserModal();
            },
            userUpdated(value) {
                let dataIndex = this.users.findIndex(user => user.id == value.id);
                this.users[dataIndex] =value;
                this.hideUpdateUserModal();
            },
            showCurrentUserUpdate() {
                let user = _.find(this.users, {email: this.user.email});
                if(!_.isEmpty(user)) {
                    this.goUserPermissionUpdatePage(user);
                }
            },
            limitManagerName(val, limit = 20) {
                if (val && (val.length > limit)) {
                    return val.substring(0, limit);
                }
                return val;
            },
        },
        async mounted() {
            await this.getUsers();
            if(this.$route.query.update == 'show') {
               await this.showCurrentUserUpdate();
            }
        },
        beforeMount() {
            EventBus.$on(SHOW_NOTIFICATION_SETTINGS, () => {
                   this.showCurrentUserUpdate();
            });
        },
        beforeDestroy() {
            EventBus.$off(SHOW_NOTIFICATION_SETTINGS);
        }
    }
</script>

<style scoped>
    .owner__badge {
        padding: 0 6px !important;
        margin-left: 3px;
        font-size: 10px
    }
</style>
